<template>
  <div>
    <Header :headerIndex="4"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner4.png" alt="" />
      <div class="case_detail_wrap">
        <div class="case_inner max_1440">
          <div class="flex-box">
            <router-link to="/case" class="g_breadcrumb">Case show</router-link>
            <div class="g_breadcrumb mlr10">></div>
            <div class="g_breadcrumb">Case show Details</div>
          </div>

          <div class="title">{{casedetail.title_en}}
            <div class="btn1" @click="toposter">Viewing Poster</div>
            <div class="btn1" @click="download" style="right:15%;">Download</div>
          </div>        
          <div class="info flex-box flex-center">
            <div class="flex-box mr50">
              <img class="logo" :src="casedetail.shop_logo" alt="" />
              <div class="white">{{casedetail.shopname}}</div>
            </div>
            <div class="mr50">{{casedetail.title_en}}</div>
            <div>{{casedetail._posttime}}</div>
          </div>
          
          <div style="color:white;" v-html="casedetail.content_en">
            <!-- {{casedetail.content_en }} -->
          </div>
          <div v-if="casedetail.videourl != ''">
            <video style="width:100%;height:500px;margin:0 auto 10px;" :src="casedetail.videourl" controls :poster="casedetail.firstpicUrl"></video>
          </div>
          <img class="img_detail" :src="casedetail.firstpicUrl" alt="" />
          <div v-for="(item,index) in casedetail.picurls">
            <img class="img_detail" :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
import JSZip from 'jszip'
import FileSaver from 'file-saver'
export default {
  components: {},
  data(){
    return {
      caseid:'',
      colorid:0,
      seriesid:0,
      casedetail:[],
      imageUrls:[]
    }
  },
  created(){
    this.caseid = this.$route.query.caseid
    console.log(this.caseid);
    this.getcasedetail()
  },
  methods:{
    getcasedetail(){
      var caseid = this.caseid
      axios.post('https://n.xilukeji.com/rwf/index.php/Shop/Shop/casedetails_2023',{cid:caseid}).then(res=>{
        res.data.info.content_en = (res.data.info.content_en).replaceAll('\r\n','<br/>')
        this.casedetail=res.data.info
        this.seriesid = res.data.info.typepid
        this.colorid = res.data.info.typeid
        this.imageUrls = res.data.info.picurls
      })
    },
    toposter(){
      this.$router.push({path:'/PosterList',query:{typepid:this.seriesid,typeid:this.colorid}});
    },
    download(){
      console.log('111')
      this.async(this.imageUrls)
    },
    // 批量下载
    async (selectImgList) {
      const data = selectImgList;
      const zip = new JSZip()
      const cache = {}
      const promises = []
      data.forEach(item => {
        const promise = this.getFile(item).then(data => { // 下载文件, 并存成ArrayBuffer对象
          const arr_name = item.split("/");
          let file_name = arr_name[arr_name.length - 1] // 获取文件名
          const file_name_arr = file_name.split('.jpg')
          file_name = file_name_arr[0]+'.jpg';
          // if (file_name.indexOf('.jpg-rwf_water_rw') == -1) {
          //    file_name = file_name + '.jpg'
          // }
          // console.log(file_name,233333)

          zip.file(file_name, data, {
            binary: true
          }) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({
          type: "blob"
        }).then(content => { // 生成二进制流
          FileSaver.saveAs(content, this.casedetail.title_en+".zip") // 利用file-saver保存文件
        })
      })
    },
    // 下载文件
    getFile (url) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url,
          responseType: 'arraybuffer'
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error.toString())
        })
      })
    }
  }
};
</script>

<style scoped>
.case_detail_wrap {
  padding: 30px 0;
}
.case_detail_wrap .title {
  position: relative;
  margin: 0 0 20px;
  padding: 30px 185px 10px;
  font-size: 20px;
  line-height: 28px;
}
.case_detail_wrap .btn1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 185px;
  height: 70px;
  background: #cf1f1a;
  border-radius: 20px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 70px;
  text-align: center;
}
.case_detail_wrap .date {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .case_detail_wrap .title{
    padding: 30px 140px 10px;
  }
  .case_detail_wrap .btn1 {
    width: 139px;
    height: 53px;
    border-radius: 15px;
    font-size: 14px;
    line-height: 53px;
  }
}
@media screen and (max-width: 1024px) {
  .case_detail_wrap .title{
    padding: 30px 100px 10px;
  }
  .case_detail_wrap .btn1 {
    width: 99px;
    height: 37px;
    background: #CF1F1A;
    border-radius: 11px;
    font-size: 10px;
    line-height: 37px;
  }
}
@media screen and (max-width: 767px) {
  .case_detail_wrap .title{
    padding: 30px 15px 10px;
    margin: 0 0 10px;
  }
  .case_detail_wrap .btn1 {
    position: static;
    margin: 10px 0 0;
    width: 100%;
  }
}
</style>
